<template>
  <div>
    <div ref="pageEditor" class="mb-3">
      <b-tabs content-class="mt-3 w-100">
        <b-tab title="Éditeur" active class="py-4">
          <textarea
            class="form-control editor"
            :class="{ 'is-invalid': hasError }"
            ref="editor"
            :id="inputId"
            :aria-describedby="helpTextId"
            :placeholder="placeholder"
            :value="value"
            @keyup="editorHeight"
            @input="handleChange">
          </textarea>
        </b-tab>
        <b-tab title="Aperçu" class="py-4">
          <div class="p-2 preview markdown-table" v-html="compiledMarkdown"></div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html'
import marked from 'marked'
import { BTabs, BTab } from 'bootstrap-vue'
import inputMixin from '@/modules/forms/components/inputs/inputMixin'

export default {
  components: {
    'b-tabs': BTabs,
    'b-tab': BTab
  },
  mixins: [inputMixin],
  data () {
    return {
      textareaSize: 36
    }
  },
  computed: {
    compiledMarkdown () {
      return this.value ? sanitizeHtml(marked(this.value, { breaks: true, gfm: true })) : ''
    }
  },
  methods: {
    editorHeight () {
      this.$nextTick(() => {
        let position = document.documentElement.scrollTop || document.body.scrollTop

        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
          position += 20
        }

        this.$refs.editor.style.height = 'auto'
        this.$refs.editor.style.height = `${this.$refs.editor.scrollHeight}px`

        document.documentElement.scrollTop = position
      })
    }
  },
  created () {
    this.editorHeight()
  }
}
</script>

<style lang="scss" scoped>
.editor {
  height: 38px;
  line-height: 20px;
  overflow: hidden;
  resize: none;
}
.preview {
  border: 1px solid #ced4da;
  min-height: 38px;
  cursor: not-allowed;
}
</style>
